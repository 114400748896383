// nav toggle
#nav-toggle-group {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 160000;

  .active-nav & {
    transition: transform 1s;

    @include media-breakpoint-up(sm) {
      transform: translateX(-1 * $nav-mobile-width-sm);
    }
    @include media-breakpoint-up(md) {
      transform: translateX(-1 * $nav-mobile-width-md);
    }
    @include media-breakpoint-up(lg) {
      transform: translateX(-1 * $nav-mobile-width-lg);
    }
  }
}

#nav-toggle-label {
  position: relative;
  padding: 0.75em 2rem 3.75rem 2rem;
  overflow: hidden;
  width: 165px;
  display: flex;
  justify-content: flex-end;

  @include media-breakpoint-up(lg) {
    width: 140px;
  }

  &::after {
    content: "";
    background-image: linear-gradient(
      100deg,
      $main-theme-hover,
      $heifa_darkgreen 80%
    );
    display: block;
    position: absolute;
    transform: rotate(10deg);
    top: -20px;
    left: -5px;
    right: -10px;
    bottom: 15px;
    z-index: -1;
  }

  .nav-toggle {
    display: block;
    overflow: visible;
    &::before {
      content: "MENÜ";
      text-indent: 0;
      position: relative;
      color: #fff;
      display: block;
      float: left;
      font-size: 2rem;
      margin-top: 8px;
      margin-left: -3.25em;
    }
  }
}
@include media-breakpoint-down(sm) {
  .active-nav {
    #nav-toggle-label::after {
      content: none !important;
    }

    .nav-toggle {
      &::before {
        content: none !important;
      }
    }
  }
}

// nav content
#nav-main-addition {
  padding-right: 2rem;
  order: -2;

  @include media-breakpoint-down(sm) {
    padding-right: 6rem;

    & li > a {
      padding: 2rem 1rem !important;
    }
  }
}

.nav-main-container {
  right: 0;
  transform: translateX(100%);
  transition: transform 1s;

  .active-nav & {
    opacity: 1;
    transform: none;
  }

  .nav-main-group .nav-main-items {
    border-top: none;
  }
}

.nav-main-mobile-logo {
  display: none;
}

.nav-main-items {
  li.ed,
  .nav-menu li:not(:last-of-type) {
    > a {
      border-bottom: none;
    }
  }
}

// Sprachlinks
#nav-main-addition {
  display: block !important;
  padding-bottom: 1rem;

  #label_nav-main-addition {
    display: none;
  }

  #nav_nav-main-addition {
    max-height: none;
    display: flex;
    justify-content: flex-end;

    li > a {
      padding: 15px;
      border-bottom: none;
    }
  }
}

// Search
#nav-main-search {
  display: flex !important;
  order: -1;
}

#label_nav-main-search {
  display: none;
}

#nav_nav-main-search {
  max-height: 1000px;
  padding: $nav-mobile-line-padding $nav-mobile-indent-offset;

  .search-form {
    border: none;

    #searchNavQuery {
      border: none;
      background-color: $heifa_darkgreen-form;
      border: 1px solid #fff;
      border-right: none;
      color: #fff;
    }

    .btn {
      text-indent: -9999999px;
      background-color: $heifa_darkgreen-form;
      border: 1px solid #fff;
      border-left: none;
      padding: 7px 16px;

      &::after {
        content: "\f002";
        text-indent: 0;
        display: block;
        float: left;
        font: normal normal normal 14px/1 var(--my-icon-font-family);
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        background-color: $heifa_darkgreen-form;
      }

      &:hover,
      &:focus {
        background-color: $heifa_darkgreen;
        &::after {
          background-color: $heifa_darkgreen;
        }
      }
    }
  }
}

.heifa_iconlinks {
  margin: 4rem 0;
  .list-entry {
    margin-bottom: 6rem !important;

    .teaser {
      text-align: center;

      .visual {
        .image-src-box {
          max-width: 6rem;
          margin: 0 auto;
        }
      }

      .body {
        min-height: initial !important;
      }

      .link {
        text-align: center;
        width: 100%;
      }
    }
  }
}

@mixin heifa_boxgradient($angle: 90deg) {
  background-image: linear-gradient($angle, $main-theme-hover, $main-theme);
}

@mixin heifa_boxgradient_red($angle: 90deg) {
  background-image: linear-gradient($angle, #c3361e, #6a140f);
}

@mixin heifa_imagegradient() {
  &::before {
    content: "";
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    z-index: 2;
    background-image: linear-gradient(
      52deg,
      rgba(15, 77, 82, 1) 0%,
      rgba(46, 56, 54, 0.85) 25%,
      rgba(113, 75, 61, 0) 50%,
      rgba(161, 84, 61, 1) 75%,
      rgba(193, 53, 29, 1) 100%
    );
    opacity: 0.58;
  }
}

.heifa_stoerer {
  position: unset;

  .visual {
    position: absolute;
    left: 0;
    max-width: 440px;
    display: inline-block;
    margin-top: -110px;
    z-index: 10;

    @include media-breakpoint-down(sm) {
      top: 255px;
      margin-top: 0;
    }
    @media (max-width: 470px) {
      max-width: 80%;
    }
    @include media-breakpoint-up(sm) {
      top: 50rem;
    }
  }
  .text {
    display: inline-block;
    max-width: 40%;
    float: right;
    margin-top: 2rem;
    margin-right: 6rem;
    margin-bottom: 4rem;
    // @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    @include media-breakpoint-up(sm) {
      margin-top: 4rem !important;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      float: none;
      margin: 0 4rem;
    }
  }
}

body {
  font-family: $heifa_font_light;
  font-size: 15px;
  line-height: 27px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $heifa_font_bold;
  letter-spacing: 0.015em;
}

.heifa_sectionhead {
  .heading {
    text-align: center;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 4rem;
      margin-bottom: 1em;
      color: $main-theme;
    }
  }
  .text {
    max-width: 80%;
    margin: 0 auto;
    margin-top: -1em !important;
    margin-bottom: 4rem;
    text-align: center;
    font-family: $heifa_font_light;
    font-size: 13px;
    line-height: 17px;
  }

  .headline {
    h2,
    h3,
    h4 {
      font-family: $heifa_font_bold;
    }
  }
}

.heifa_headline-underllined {
  .heading {
    display: block;
    border-bottom: 2px solid $heifa_darkgreen;
  }
}

.heifa_gradientbox {
  @include heifa_boxgradient;
  color: #fff;
  margin-bottom: 1rem;
  --my-btn-bg: #{$heifa_darkgreen};
  --my-btn-hover-bg: #{$main-theme-hover};
  a,
  a:hover,
  a:visited,
  a:active {
    color: #fff;
    text-decoration: underline;
  }
  .head {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #fff;
    }
  }
  &--hero {
    padding: 6rem 0 !important;
    .head-text {
      text-align: center;
      .head {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 4rem;
          margin-bottom: 0.5em;
        }
      }
      .text {
        max-width: 80%;
        margin: 0 auto;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &--red {
    @include heifa_boxgradient_red;
  }
  &--imgtxt {
    padding-top: 4rem !important;
    .ap-section {
      &.image-only {
        margin-bottom: 0;
      }
      &.head-text {
        padding-top: 2rem;
        @include media-breakpoint-down(md) {
          padding-bottom: 2rem;
        }
        @include media-breakpoint-down(sm) {
          padding: 2rem;
        }
        .head {
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-size: 3rem;
            margin-bottom: 0.5em;
          }
        }
        .link {
          margin-top: 3rem;
        }
      }
    }
  }
}

.h-logo {
  width: 18rem;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.heifa_subpage_header {
  .h-logo {
    @include media-breakpoint-down(md) {
      width: 12rem;
    }
    @include media-breakpoint-down(sm) {
      width: 7rem;
    }
  }
}

.area-foot {
  margin-top: 6rem;

  .topfoot {
    padding: 4rem 0;
  }

  .subfoot {
    background-color: #fff;
  }
  .heifa_logo {
    width: 49% !important;
    display: inline-block;
    vertical-align: top;
  }

  .heifa_headline-underllined {
    .piece-heading {
      font-size: 3rem;
      margin-top: 6rem;
    }
  }
}

.heifa_footer_bistum {
  padding-top: 0;
  & > .container {
    display: none;
  }
}

.heifa_square-list {
  .square-large {
    margin-bottom: -1px;
  }

  .text-overlay {
    min-height: initial !important;
    padding-bottom: 10px;
    &::before {
      display: block;
      content: "";
      background: $main-theme-hover;
      opacity: 0.9;
      transform: rotate(10deg);
      position: absolute;
      z-index: -1;
      left: -25px;
      right: -25px;
      top: -2em;
      bottom: -100px;
    }
    .teaser-date {
      margin-bottom: 0.5em;
    }
    h3 {
      display: none;
    }
  }
  // @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  @include media-breakpoint-up(sm) {
    .square-lg-8 {
      .text-overlay::before {
        top: -4em;
      }
    }
  }
  .ap-square.text-below-on-xs:not(.play) .content .text-overlay {
    background: transparent !important;
    position: absolute !important;
  }
  .image-src-box {
    @include heifa_imagegradient;
  }
}

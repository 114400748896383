.ap-download-list {
  .dl-content {
    display: block !important;
  }
  .dl-info {
    display: inline-block !important;
  }
  .dl-dl {
    display: inline-block !important;
    float: right !important;
  }
}

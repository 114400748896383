.countdown_wrapper {
  position: absolute;
  left: 0;
  top: 18.5rem;
  z-index: 999;
  transition: opacity 0.5s ease;
  .countdown {
    color: #fff;
    font-size: 1.5rem;
    font-family: museo700;
    letter-spacing: 0.015em;
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
    @include media-breakpoint-up(sm) {
      flex-direction: column;
      font-size: 3rem;
    }
    @include media-breakpoint-down(sm) {
      transform: skew(0, 9.5deg);
      @include heifa_boxgradient_red;
    }

    .linewrapper {
      @include media-breakpoint-up(sm) {
        transform: skew(0, 9.5deg);
        @include heifa_boxgradient_red;
        margin-bottom: 0.5rem;
        padding: 0.75rem 2rem;
        margin-left: -5rem;
        padding-left: 7rem;
      }
      @include media-breakpoint-down(sm) {
        padding: 0.25em;
        &:first-of-type {
          padding-right: 0;
          &::after {
            content: ",";
          }
        }
      }
    }

    &_line {
      color: #fff;
      font-size: 1.5rem;
      font-family: "museo500";
      transform: skew(0, -9.5deg) rotate(9.5deg);
      @include media-breakpoint-up(sm) {
        font-size: 2.2rem;
        display: block;
      }
    }
  }
}

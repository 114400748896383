.heifa_mediabox {
  .ap-media {
    .teaser-body {
      .teaser-text {
        display: none;
      }
    }
    .image-src-box {
      @include heifa_imagegradient;
    }
  }
  &--big {
    .ap-square.square-4-3 {
      &.square-lg-4,
      &.square-md-4,
      &.square-sm-4 {
        @include media-breakpoint-up(sm) {
          width: 50%;
          padding-bottom: 445px !important;
        }
      }
      .text-overlay {
        padding: 0 2rem 2rem !important;
        h1,
        h2,
        h3,
        h4 {
          h1,
          h2,
          h3,
          h4 {
            @include media-breakpoint-up(md) {
              font-size: 3rem;
            }
            @include media-breakpoint-up(sm) {
              padding-right: 30%;
            }
          }
          @include media-breakpoint-down(sm) {
            padding-right: 30%;
          }
        }
      }
    }
  }
}

.heifa_nav_ls {
  position: absolute;
  z-index: 1000000;
  right: 15px;
  top: 100px;
  width: 80px;
  height: 80px;

  .active-nav & {
    transition: transform 1s;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    @include media-breakpoint-up(sm) {
      transform: translateX(-1 * $nav-mobile-width-sm);
    }
    @include media-breakpoint-up(md) {
      transform: translateX(-1 * $nav-mobile-width-md);
    }
    @include media-breakpoint-up(lg) {
      transform: translateX(-1 * $nav-mobile-width-lg);
    }
  }

  a {
    cursor: pointer;
    filter: grayscale(1);

    &:hover,
    &:active {
      filter: grayscale(0);
    }
  }
}

.heifa_startslider {
  --my-slider-fg: #{$heifa_darkgreen};
  --my-slider-bg: 255, 255, 255;
  --my-font-family-headings: #{$heifa_font_bold};
  --my-font-weight-headings: bold;
  --my-default-margin-verysmall: 1.5rem;

  @include media-breakpoint-up(sm) {
    --my-default-margin-small: 4rem;
    --my-default-margin-verysmall: 3rem;
    --my-default-margin: 8rem;
  }

  .caption {
    @include media-breakpoint-down(sm) {
      margin-top: var(--my-default-margin-small);
    }

    small,
    strong {
      text-shadow: none !important;
    }
  }
}
